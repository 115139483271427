<template>
  <div>
    <Header heading="Analytics 2.0" class="mb-4">
     
    </Header>
    <div class="card border-radius" style="border:1px solid var(--border-color)">
      <div class="card-body" style="overflow:auto">
       <div class="">
        <div class="d-flex align-items-center menu mb-3">
          <div  @click="toggleTab('Insights')"
          :class="
            togglePageType == 'Insights'
              ? 'active'
              : ''
          ">Quiz</div>
                        <div  :class="
            togglePageType == 'Products'
              ? 'active'
              : ''
          " @click="toggleTab('Products')">Products</div>
                    </div>
       </div>
      <template  v-if="(quizzes.length<1&&(!loadingFilter))">

        
        <div class="d-flex justify-content-center align-items-center" style="height: 50vh;" >

<EmptyTable @click="$router.push('/manage/quizzes')"  :showButton="true" btnTitle="Return to quizzes">
  <template #title>
    No quiz found
  </template>
  <template #description>
    Start crafting your quiz to unlock comprehensive analytics insights.
  </template>
</EmptyTable>
</div>
                 
                </template>
                <template v-else>
    <div class="w-100 d-flex justify-content-center align-items-center" >
    
    </div>
    <div class="">

      <div class="">
        <!-- <b-collapse id="collapse-1" visible  > -->
        <div class="d-flex my-2 flex-wrap" style="gap: 5px">
          <FilterSelect
            ref="quiz"
            :staticOptions="quizzes"
            :isDynamic="false"
            :selectedArray="selectedQuiz"
            filterFor="quizzes"
            @update="getUpdatedSelectedQuiz"
          ></FilterSelect>
          <FilterSelect
           :isDynamic="false"
            :isCalender="true"
            @updateValues="updateValues"
          ></FilterSelect>
        </div>
      </div>

      <div v-if="!isAnalysisLoading && noQuizView">
      
        <div class=" mt-3 d-flex align-items-center justify-content-center" style="min-height: 45vh;">
          <div class=" noQuizFoundContainer" style="position: realtive">
                     <h1 class="titleText">No Quiz found!</h1>
                     <h2 class="subText my-2">Start crafting your quiz now to unlock comprehensive analytics insights</h2>
                     <div class="text-center mt-2">
                      <router-link class="routeLink ButtonHover" to="/manage/quizzes">Return to Quizzes</router-link>
                     </div>
                    </div>
        </div>
      </div>
      <div v-else>
        <div class="row" v-if="isAnalysisLoading">
        <div class="col-12 col-md-6 col-lg-3" v-for="index in 4" :key="index">
          <div class="analytics-box p-0">
            <div class="p-0">
              <div
                class="bg-preload my-2"
                style="height: 100px; width: 100%; border-radius: 4px"
              >
                <div class="into-preload"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row py-3" v-else>
        <div
          v-for="(value, key) in mainAnalysis"
          :key="key"
          class="col-12 col-md-6 col-lg-3 py-2"
        >
          <div class="card analytics-box">
            <div class="card-body py-3">
              <div class="">
                <div class="pr-2 text-bold analytic-card-title" >{{ names[key] }}</div>
                <div class="v16-description mt-1">{{getAnalyticsToolTips(names[key])}}</div>
               
              </div>
              <div class="mt-4 ">
                <div class="" >
                  <span class="animated-number" id="counter" :ref="key"
                  >{{ animatedNumbers[key] }}
                  {{ key == "completion" ? "%" : "" }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="">
      <div class="" v-if="togglePageType == 'Insights'">
        <AnalyticsGraph
          @loadGraph="loadGraph"
          :isV1="isV1"
          ref="analyticsGraph"
          :quizzes="quizzes"
          :isLoading="isAnalysisLoading"
          :graphAnalysis="graphAnalysis"
          :totalLeads="mainAnalysis.totalLeads"
        ></AnalyticsGraph>
        <WorldChropleth :isLoading="isMapLoading" :mapData="mapData" />
        <AnalyticsTable
          ref="analyticTable"
          :quizzes="quizzes"
          :quizIds="filter.quiz_ids"
          :filter="filter"
          @getAnalyticsTableData="getAnalyticsData"
          :isQuestionLoading="isQuestionLoading"
          :AnswerStats="AnswerStats"
        ></AnalyticsTable>
      </div>
      <ProductAnalytics
        v-else
        ref="analyticProducts"
        :startDate="filter.start_date"
        :endDate="filter.end_date"
        :quizzes="quizzes"
        :Token="Token"
        :quizIds="filter.quiz_ids"
      ></ProductAnalytics>
    </div>

      </div>

      
    </div>
   </template>
  </div>
   </div></div>
</template>

<script>
// import hljs from 'highlight.js';
// import ''
import FilterSelect from "../Layout/FilterSelect.vue";
import "vue-tel-input/dist/vue-tel-input.css";
import ProductAnalytics from "./ProductAnalytics";
import AnalyticsGraph from "./AnalyticsGraph.vue";
import AnalyticsTable from "./AnalyticsTable.vue";
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
// import Header from "../Layout/Header.vue";
import Header from "../Layout/Header.vue";
import WorldChropleth from "./WorldChropleth.vue";
import EmptyTable from "../Layout/EmptyTable.vue";
export default {
  components: {
    Header,
EmptyTable,
    AnalyticsGraph,
    AnalyticsTable,
    ProductAnalytics,

    FilterSelect,
    // DateRangePicker,
    WorldChropleth,
  },

  data() {
    return {
      noQuizView: false,
      selectedQuiz: [],
      previousDataAvailable: false,
      isV1: false,
      animatedNumbers: {
        start: 0,
        submission: 0,
        totalLeads: 0,
        completion: 0,
      },
      isLoadingCardStats: false,
      animeScriptLoaded: false,
      intervals: {}, // Store intervals for each property
      names: {
        start: "Started",
        submission: "Finished",
        completion: "Completion rate",
        totalLeads: "Leads",
      },
      togglePageType: "Insights",
      mainAnalysis: [],
      graphAnalysis: [],
      isAnalysisLoading: true,
      DateDropdown: [
        {
          text: "All",
          value: "all",
        },
        {
          text: "Custom Date",
          value: "customDate",
        },
      ],
      selectedDateValue: "all",
      QuizID: "",
      QuizKey: "",

      Token: "",
      loadingFilter: false,
      quizzes: [],
      filter: {
        title: "",
        quiz_ids: [],
        start_date: "",
        end_date: "",
      },
      range: {
        startDate: null,
        endDate: null,
      },
      today: new Date(),
      childPageType: "Insights",
      mapData: [],
      isMapLoading: false,
      isQuestionLoading: false,
      AnswerStats: [],
    };
  },
  computed: {
    calculateCompletion() {
      let value;
      if (this.mainAnalysis.start && this.mainAnalysis.submission) {
        value = Math.ceil(
          (this.mainAnalysis.submission / this.mainAnalysis.start) * 100
        );
      }

      return value > 100 ? 100 : value;
    },
    payload() {
      let dataObject = {
        quizIds: this.filter.quiz_ids,
      };
      if (this.filter.start_date && this.filter.end_date) {
        dataObject.start_date = this.filter.start_date;
        dataObject.end_date = this.filter.end_date;
      }
      return dataObject;
    },
    ...mapGetters(["GetTokenFromMetaTag", "getQuizID"]),
  },
  watch: {
    isV1() {
      this.callbackFunc();
    },
    mainAnalysis: {
      handler() {
        this.startCounting();
      },
      deep: true,
    },
    togglePageType(newValue) {
      if (newValue == "Insights") {
        this.loadGraph(this.isV1);
        const timeout = setTimeout(() => {
          this.$refs.analyticTable.getAnalyticsData(this.isV1);
          clearTimeout(timeout);
        }, 0.3);
      } else {
        const timeout = setTimeout(() => {
          this.$refs.analyticProducts.getAnalyseData(this.isV1);
          clearTimeout(timeout);
        }, 0.3);
      }
    },
  },
  methods: {
   
    async getUpdatedSelectedQuiz(e) {
      this.filter.quiz_ids = await e.map((q) => q.id);
      this.callbackFunc();
    },
    async getGeoAnalyticsData() {
      try {
        this.isMapLoading = true;

        const response = await axios.post(
          "/api/v1/quiz-geo-analytics",
          this.payload
        );
        if (response.status == 200) {
          const { data } = response.data;
          let modifiedData = data.map((item) => {
            return {
              id: item.country_code,
              name: item.country_name,
              total_leads: item.total_leads,
              percentage: item.percentage,
            };
          });
          this.mapData = modifiedData;
        }
      } catch (error) {
        this.$toasted.show(
          "Opps! Something went wrong for geo location data.",
          {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          }
        );
        throw error;
      } finally {
        this.isMapLoading = false;
      }
    },
    getAnalyticsToolTips(value) {
      if (value == "Started") return `Total of Quizzes Initiated`;
      if (value == "Finished") return `Total of Quizzes Completed`;
      if (value == "Leads") return `Total Leads Generated`;
      if (value == "Completion rate") return `Total Quiz Completion Rate`;
    },
    loadAnimeScript() {
      const animeScript = document.createElement("script");
      animeScript.src = "https://cdn.jsdelivr.net/npm/animejs";

      animeScript.onload = () => {
        this.animeScriptLoaded = true;
      };

      document.head.appendChild(animeScript);
    },
    startCounting() {
      if (!this.animeScriptLoaded) return;

      for (const key in this.mainAnalysis) {
        setTimeout(() => {
          this.animateNumber(key);
        }, 0.3);
      }
    },
    animateNumber(key) {
      const obj = { n: 0 }; // Object to hold the number value

      // eslint-disable-next-line no-undef
      anime({
        targets: obj,
        n: this.mainAnalysis[key],
        round: 1,
        duration: 500,
        easing: "linear",
        update: () => {
          this.animatedNumbers[key] = Math.round(obj.n);
        },
      });
    },

    toggleTab(tab) {
      this.togglePageType = tab;
    },
    async loadGraph(isV1 = false) {
      let api = isV1 ? "/loadAnalyses" : "/api/v1/quiz-analytics";
      this.isAnalysisLoading = true;
      await axios
        .post(api, this.payload)
        .then((response) => {
          if (response.status) {
            this.mainAnalysis = response.data.data.main_analyses;
            this.graphAnalysis = response.data.data.graphic_analyses;
            if (!isV1)
              this.previousDataAvailable =
                response.data.data.previousDataAvailable;

            this.mainAnalysis.completion = this.calculateCompletion;
          }
        })
        .catch((e) => {
          console.log(e);
          throw e;
        });
      this.isAnalysisLoading = false;
    },
    async getAnalyticsData(isV1 = false) {
      this.isV1 = isV1;
      const api = isV1
        ? "/loadAnalyses/question"
        : `/api/v1/question-analytics`;
      this.isQuestionLoading = true;
      try {
        const response = await axios.post(api, this.payload);
        if (response.status) {
          this.AnswerStats = response.data.data;
          // console.log("Get Analaytics DAta REsponse",response.data.data)
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isQuestionLoading = false;
      }
    },
    customDateFormat(date, isUpdateFormate = false) {
      const inputDate = new Date(date);

      const day = String(inputDate.getDate()).padStart(2, "0");
      const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = inputDate.getFullYear();

      const formattedDate = isUpdateFormate
        ? `${year}-${month}-${day}`
        : `${day}-${month}-${year}`;
      return formattedDate;
    },

    getDateFormatter() {
      // Return a function that formats the date as required ('yyyy-mm-dd')
      return (date) => {
        const formattedDate = new Date(date).toISOString().substring(0, 10);
        return formattedDate;
      };
    },
    updateValues(dates) {
      const startDate = dates.startDate
        ? this.customDateFormat(dates.startDate, true)
        : "";
      const endDate = dates.endDate
        ? this.customDateFormat(dates.endDate, true)
        : "";
      this.filter.end_date = endDate;
      this.filter.start_date = startDate;
      this.callbackFunc();
    },

    ...mapMutations(["setQuizKey"]),

    async getFilter() {
      this.loadingFilter = true;
      await axios
        .post("/filter", { page_name: "lead" })
        .then((response) => {
          this.quizzes = response.data.data.quizzes;
          if (this.quizzes.length == 0) {
            this.noQuizView = true;
            this.loadingFilter = false;
            this.isAnalysisLoading = false;
            return;
          }

          this.filter.quiz_ids = [this.quizzes[0].id];
          this.selectedQuiz = [this.quizzes[0]];
        })
        .catch((e) => console.log(e));

      this.loadingFilter = false;
    },
    clearFilter() {
      this.filter = {
        title: "",
        quiz_ids: [],
        start_date: "",
        end_date: "",
      };
      this.selectedDateValue = "all";
    },
    async callbackFunc() {
      if (this.payload.quizIds.length > 0) {
        if (this.togglePageType == "Products") {
          this.$refs.analyticProducts.getAnalyseData(this.isV1);
        } else {
          // this.$refs.analyticTable.getAnalyticsData(this.isV1);
          this.getAnalyticsData(this.isV1);
        }
        this.loadGraph(this.isV1);
        this.getGeoAnalyticsData();
      }
    },
  },
  async mounted() {
    this.loadAnimeScript();
    this.Token = this.GetTokenFromMetaTag;
    this.QuizID = this.getQuizID;
    await this.getFilter();
    // this.callbackFunc();
  },
  beforeDestroy() {
    // Clear intervals and remove the anime.js script when the component is destroyed
    // this.clearIntervals();

    const animeScript = document.querySelector(
      'script[src="https://cdn.jsdelivr.net/npm/animejs"]'
    );
    if (animeScript) {
      animeScript.remove();
    }
  },
};
</script>

<style scoped>

.page-item.active .page-link,
.show > .btn-primary.dropdown-toggle {
  z-index: 3;
  color: #4d4950 !important;
  background-color: #f4f3f5 !important;
  border-color: #f4f3f5 !important;
}
.page-link {
  color: #4d4950 !important;
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #323232 !important;
  background-color: #323232 !important;
}
.answer-body {
  height: 510px;
  display: block;
  overflow: scroll;
}
.mainBG {
  background-image: linear-gradient(
    to top,
    #ffffff,
    #ffff 15%,
    #ffffff 25%,
    #ffffff 74%,
    #f4f3f5 73%
  ) !important;
  min-height: 100vh;
}
.mainBG2 {
  background-image: linear-gradient(
    to top,
    #ffffff,
    #ffff 15%,
    #ffffff 25%,
    #ffffff 62%,
    #f4f3f5 62%
  ) !important;
  min-height: 100vh;
}

.toggleButton {
  background: transparent;
  color: #292929;
  font-size: 14px;
  font-weight: 400px;
}
.toggleSelectedButton {
  background: rgba(77, 27, 126, 0.8) !important;
  color: #ffffff !important;
  font-size: 14px !important;
  font-weight: 500px !important;
}
.toggleSelectedButton:hover {
  color: #ffffff !important;
}

.analyse-box {
  border-radius: 20px !important;
  box-shadow: 0px 4px 10px rgba(10, 4, 17, 0.15);
}
.analyse-box hr {
  border-top: 0.3px solid #4d4950;
}
.progress-head {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
.bg-yello-theme {
  background-color: #ffa201 !important;
}
.AnswerStatsProgress .progress {
  background-color: #f4f3f5;
  height: 5px;
  border-radius: 1.25rem;
  width: 115px;
}
.table thead th {
  border-top: none;
}

.AnsStatsTable {
  /* height: 420px !important; */
  height: 100%;
  max-height: 420px !important;
  overflow: hidden scroll !important;
}

/* Lead Data Table Styles */
.analytics-box {
  border-radius:  8px;
border: 1px solid #EDEDED;
background: var(--White, #FFF);

/* Shadow/sm */
box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}
.LeadDataTable .card-header {
  display: none !important;
}
.LeadDataTable .card-footer {
  background: transparent;
  padding: 0 !important;
  border: none;
}

.LeadDataTable .vbt-per-page-dropdown > .btn-primary {
  background: rgba(77, 27, 126, 0.8) !important;
  border: none !important;
  color: #ffffff !important;
}
.LeadDataTable .vbt-per-page-dropdown .dropdown-item.active {
  background: rgba(77, 27, 126, 0.8) !important;
  text-decoration: none !important;
  color: #ffffff !important;
}

/* Date Range & Dropdown Styles */
.dateDiv {
  border: 0.8px solid #b3afb6;
  box-sizing: border-box;
  border-radius: 6px;
}
.dateDiv button,
.dateDiv button:hover {
  background: transparent !important;
  color: #18191c;
  outline: none;
  text-decoration: none;
}
.datePicker {
  border: 0.8px solid #b3afb6 !important;
  box-sizing: border-box;
  border-radius: 6px;
  background: transparent !important;
}
.CustomDateButtons button {
  color: #ffffff;
  border-radius: 6px;
}
.CustomDateButtons button:hover {
  color: #ffffff;
}
.CustomDateButtons button.update {
  background: rgba(77, 27, 126, 0.8);
}
.CustomDateButtons button.reset {
  background: #b3afb6;
}
.q-collapse::after {
  content: "";
  width: 100%;
  display: block;
  border-radius: 4px;
  height: 1px;
  border: 1px solid rgb(206 206 210 / 25%);
}
.collapse-title {
  font-size: 18px;
  font-weight: 500;
}
.custom-row-class:hover {
  cursor: pointer; /* Change this to the desired cursor style */
}

.collapsed > .collapse-title > .when-opened,
:not(.collapsed) > .collapse-title > .when-closed {
  display: none;
}
.date >>> #Starting,
.date >>> #ending {
  /* color: #3f1768; */
  color: #4d1b7e;
}
.btn-filter,
.btn-filter:hover {
  border-radius: 4px;
  outline: none;
  border: 1px solid #4d1b7e;
  color: #4d1b7e;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.radio-button
  >>> .custom-control-input:checked
  ~ .custom-control-label::before {
  background: #ffa201;
  border-color: #ffa201;
}
.radio-button >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #ffa201;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ffa201;
  background-color: #ffa201 !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #ffa201;
}
.toggleButton {
  background: transparent;
  color: #292929;
  font-size: 14px;
  font-weight: 400px;
}
.toggleSelectedButton {
  background: rgba(77, 27, 126, 0.8) !important;
  color: #ffffff !important;
  font-size: 14px !important;
  font-weight: 500px !important;
}
.toggleSelectedButton:hover {
  color: #ffffff !important;
}
#dropdown-form {
  padding: 0.25rem;
  min-width: 200px;
}
#dropdown-form >>> .b-dropdown-form {
  padding: 0.25rem;
}
#dropdown-form >>> .QuizzesDropdownContainer {
  max-height: 220px;
  overflow-x: hidden;
  overflow-y: auto;
}

.filter-btn {
  background: #fff;
  border: navajowhite;
  box-shadow: 0 -0.0625rem 0 0 #b5b5b5 inset, 0 0 0 0.0625rem #0000001a inset,
    0 0.03125rem 0 0.09375rem #fff inset;
  border-radius: 0.5rem;
  padding: 5px 11px;
}
.filter-btn:focus {
  box-shadow: 0 -0.0625rem 0 0 #b5b5b5 inset, 0 0 0 0.0625rem #0000001a inset,
    0 0.03125rem 0 0.09375rem #fff inset !important;
}
.filter-btn:active {
  box-shadow: -0.0625rem 0 0.0625rem 0 rgba(26, 26, 26, 0.122) inset,
    0.0625rem 0 0.0625rem 0 rgba(26, 26, 26, 0.122) inset,
    0 0.125rem 0.0625rem 0 #1a1a1a33 inset !important;
}
.analytic-card-title{color: #1F1F28;

font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;}
.animated-number{
  color: #1F1F28;
font-family: Poppins;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 44px; /* 122.222% */
letter-spacing: -0.72px;
}
.menu{
    gap:20px;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 15px;
}
.menu div{
    color: hsl(240, 10%, 50%);
cursor:pointer;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; 
text-decoration: none;
position: relative;
}
.menu div.active{
    color:var(--primary-color)
}
.menu div.active:after{
    display: block;
    content: '';
    position: absolute;
    bottom: -15px;
    height: 2px;
    /* margin-bottom: 20px; */
    width: 100%;
    background: var(--primary-color);
    border-radius: 4px 4px 0px 0px;
}
</style>
